<template>

<div class="mr-8 ml-6 mt-5 mb-5   md:mr-20 md:ml-14 md:mt-12 md:mb-12    lg:mr-36 lg:ml-32 lg:mt-20 lg:mb-20">

    <header class="flex justify-center items-center mb-8">
        <h1 class="text-4xl font-bold text-center mr-6">Le projet Econect</h1>

        <img class="object-contain h-30 w-20 mt-3.5" src="@/assets/img/logo_econect.png" />
    </header>


    <div>
        <h2 class="text-lg ml-3 my-2 font-bold">Présentation du projet :</h2>

        <p class="mt-1.5 ml-1.5 mr-2 text-justify margel">Avec ECONECT, nous proposons de développer de nouveaux systèmes
            sentinelles de
            l’environnement autonomes, connectés et évolutifs qui pourront à la fois mesurer la pollution présente
            dans
            l’eau, l’air ou les sols et ses conséquences sur des organismes bio-indicateurs (algues d’eau douce,
            abeille
            domestique et mésanges).</p>
        <p class="mt-3 ml-1.5 mr-2 text-justify margel">Nous utiliserons des protocoles de sciences participatives
            (lycées
            agricoles,
            associations, apiculteurs, ornithologues, agriculteurs) à la fois pour la gestion locale de
            l’équipement, la
            récupération de données écologiques complémentaires et pour sensibiliser un large public aux problèmes
            environnementaux de notre région. Cette approche sera menée sous la supervision de chercheurs
            travaillant
            dans les domaines de l’éducation à l’environnement et des didactiques des sciences.</p>

        <div class="flex justify-center">
            <a class="bg-blue-700 text-white font-medium text-lg mt-6  hover:bg-blue-500 rounded-lg p-2"
                href="https://econect.cnrs.fr/" target="_blank" title="Econect">Visiter le site d'Econect</a>

        </div>

    </div>

    <div>
        <h2 class="text-lg ml-3 font-bold  mt-14">Les financeurs :</h2>
        <div class="flex place-items-center grid gap-4 grid-cols-5 mt-3">
            <img class="img" src="@/assets/img/Logo/ue.jpg">
            <img class="img" src="@/assets/img/Logo/occitanie.jpg">

    
        </div>

    </div>



    <div>
        <h2 class="text-lg font-bold mt-14 ml-5">Les partenaires :</h2>

        <div class="flex place-items-center grid gap-4 grid-cols-5 mt-5">
            <img class="img" src="@/assets/img/Logo/agence-de-leau.jpg">
            <img class="img" src="@/assets/img/Logo/agri.jpg">
            <img class="img" src="@/assets/img/Logo/Centre_national_de_la_recherche_scientifique.jpg">
            <img class="img" src="@/assets/img/Logo/CRBPO.jpg">
            <img class="img" src="@/assets/img/Logo/DGER.jpg">
            <img class="img" src="@/assets/img/Logo/inp.jpg">
            <img class="img" src="@/assets/img/Logo/jean_jaures.jpg">
            <img class="img" src="@/assets/img/Logo/neocampus.jpg">
            <img class="img" src="@/assets/img/Logo/paul-sab.jpg">
            <img class="img" src="@/assets/img/Logo/ZA.jpg">

        </div>

    </div>


    <div>
        <h2 class="text-lg font-bold mt-14 ml-3 my-2">Réalisation :</h2>

        <p class="mt-3 ml-1.5 mr-2 text-justify mb-5 margel">Cette application a été réalisée par Adict solutions.<br>
            Accéder au site de Adict solutions :</p>

        <div class="flex justify-center">
            <a class="text-lg font-medium rounded-lg text-white p-2"
                href="http://www.adict-solutions.eu/" target="_blank" title="Adict solutions"><img class="img" src="@/assets/img/adict_300.jpg"></a>

        </div>

    </div>

</div>


</template>

<style>

  .img
{
    
  border-radius: 8px;
  border: 2px solid black;
  padding: 1px;
  width: 200px;
    
}

.marge
{
  margin-left: 9em;
  margin-right: 9em;
  margin-bottom: 3rem
}

.margel
{
  margin-left: 2.5em;
}

</style>
